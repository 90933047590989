<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <div class="wrapper">
          <div class="title">
            <span>KAPMOBILE.ID <br /></span>
            <span class="super">superadmin</span>
          </div>

          <div class="msg">
            <span>{{ pesan }}</span>
          </div>
          <div class="form">
            <b-form-input
              class="inputs"
              v-model="email"
              placeholder="Email"
              type="email"
              @input="checking"
            ></b-form-input>
            <b-form-input
              class="inputs"
              v-model="password"
              placeholder="Password"
              type="password"
              @input="checking"
            ></b-form-input>

            <div class="button-wrapper">
              <div class="forgot">
                <span
                  class="biru"
                  @click="() => this.$router.push('/forgot_password')"
                  >Forgot Password</span
                >
              </div>
              <b-button disabled v-if="checked == false">Log in</b-button>

              <b-button @click="doLogin" v-else-if="loading == false"
                >Log in</b-button
              >
              <b-button v-else><white-spinner /> </b-button>

              <div class="sign-in">
                <span
                  >Belum punya akun?
                  <span
                    class="biru"
                    @click="() => this.$router.push('/register')"
                    >sign up</span
                  ></span
                >
              </div>
            </div>
          </div>
        </div>
      </b-col>

      <b-col style="padding: 0">
        <div class="wrapper2">
          <div class="ads" v-for="(data, idx) in iklan" :key="idx">
            <div class="images">
              <img :src="data.image" alt="" />
            </div>
            <div class="text">
              <span>{{ data.description }}</span>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";
import { ip_server } from "@/ip_config";
import whiteSpinner from "../../components/loader/white-spinner.vue";
export default {
  components: { whiteSpinner },
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      pesan: "",
      loading: false,
      checked: false,
      iklan: [],
    };
  },
  beforeMount() {
    axios.get("https://portal.kapmobile.id/api/" + "promotion").then((res) => {
      if (res.data.meta.status == "success") {
        for (let index = 0; index < res.data.data.promotion.length; index++) {
          res.data.data.promotion[index].description.substring(0, 100);
          this.iklan.push(res.data.data.promotion[index]);
        }
        this.iklan.sort(() => {
          return 0.5 - Math.random();
        });
        this.iklan.splice(2);
      }
    });
  },
  methods: {
    checking() {
      let vm = this;
      if (vm.email && vm.password) {
        vm.checked = true;
      } else {
        vm.checked = false;
      }
    },
    doLogin() {
      let vm = this;
      vm.loading = true;
      axios
        .post(ip_server + "users/login", {
          email: vm.email,
          password: vm.password,
        })
        .then((res) => {
          if (res.data.role != "superuser") {
            vm.pesan = "Gunakan Akun Superuser Untuk Melanjutkan";
          } else {
            localStorage.setItem("token", res.data.token);
            vm.$router.push("/main_dashboard");
          }
          vm.pesan = "";
          vm.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");
$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white2": #fff,
);
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100vh;
  .title {
    font-family: "nunito", sans-serif;
    font-weight: 700;
    font-size: 48px;
    margin-top: 8%;
    margin-bottom: 4%;
    color: map-get($color, "blue");
    .super {
      text-transform: capitalize;
    }
  }
  .msg {
    font-family: "nunito", sans-serif;
    color: map-get($color, "red");
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }
  .form {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 4%;
    .inputs {
      height: 57px;
      font-family: "nunito", sans-serif;
      font-weight: 700;
      font-size: 16px;
      margin: 2% 0;
      border-radius: 6px;
    }
  }
  .button-wrapper {
    .btn {
      width: 100%;
      height: 57px;
      font-family: "nunito", sans-serif;
      font-weight: 700;
      text-transform: capitalize;
      font-size: 16px;
      border-radius: 6px;
      background-color: map-get($color, "blue");
      border: 1px solid map-get($color, "blue");
      box-shadow: 0px 20px 25px -10px rgba(15, 93, 210, 0.25);
      margin: 1% 0;
    }
    .forgot {
      font-family: "nunito", sans-serif;
      text-transform: capitalize;
      color: map-get($color, "black");
      font-weight: 300;
      font-size: 20px;
      color: map-get($color, "blue");
      cursor: pointer;
      font-weight: 700;
      text-transform: capitalize;
      cursor: pointer;
      text-align: end;
      margin-bottom: 10%;
    }
    .sign-in {
      font-family: "nunito", sans-serif;
      font-size: 20px;
      color: map-get($color, "black");
      font-weight: 300;
      margin-top: 10%;
      .biru {
        color: map-get($color, "blue");
        text-transform: capitalize;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
}

.wrapper2 {
  background-image: url("../../../src/assets/pattern.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 3%;
  .ads {
    max-width: 100%;
    min-width: 100%;
    height: 350px;
    border-radius: 10px;
    background-color: map-get($color, "white2");
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    .images {
      width: 100%;
      max-height: 75%;
      img {
        min-width: 100%;
        max-height: 100%;
      }
    }
    .text {
      padding: 2%;
      font-family: "nunito", sans-serif;
    }
  }
}
</style>