import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/before-login/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: login
  },

  // !!---------------------------AFTER LOGIN------------------------------
  {
    path: '/main_dashboard',
    name: 'main_dashboard',
    component: () => import('../views/after-login/main_dashboard.vue'),
    meta: { requiredAuth: true }
  },

  {
    path: '/features',
    name: 'features',
    component: () => import('../views/after-login/features.vue'),
    meta: { requiredAuth: true }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

import axios from 'axios';
import { ip_server } from '@/ip_config';

router.beforeEach(async function (to, from, next) {
  if (to.meta.requiredAuth) {
    try {
      let x = await axios.get(ip_server + "users/checkToken", {
        headers: {
          token: localStorage.getItem("token"),
        },
      });
      if (x.data.status == 200) {
        next()
      } else if (x.data.status == 204) {
        next({
          path: '/',
          query: { redirect: to.fullPath }
        })
      } else {
        next()
      }
    } catch (error) {
      localStorage.removeItem('token')
      next({
        path: "/",
        query: { redirect: to.fullPath }
      });
    }
  } else {
    next();
  }
});

export default router

